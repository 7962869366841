<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.create.refs.generic') }}
    </p>
    <v-data-iterator
      :items="additionalInfos"
      :options.sync="tableOptions"
      :footer-props="{
        itemsPerPageOptions: [15, 30, 50],
        itemsPerPageText: $t('general.rowsPerPage'),
        showCurrentPage: true,
        showFirstLastPage: true
      }"
      hide-default-footer
      no-data-text=""
      class="row"
    >
      <template v-slot:item="{ item }">
        <additional-infos :item="item" />
      </template>
    </v-data-iterator>
  </v-col>
</template>

<script>
import AdditionalInfos from '@/components/details/AdditionalInfos'

export default {
  name: 'AdditionalInfosGroup',
  components: {
    AdditionalInfos
  },
  props: {
    additionalInfos: { type: Array, default: () => { return [] } }
  },
  data () {
    return {
      tableOptions: {
        page: 1,
        itemsPerPage: 15,
        itemsRange: [15, 30, 50]
      }
    }
  }
}
</script>
