<template>
  <v-col
    cols="12"
    sm="6"
    md="4"
    lg="3"
  >
    <p class="text-body-2 text-uppercase blue-grey--text text--darken-3">
      {{ $t('partnerCategories.' + item.category) }}
    </p>
    <p class="text-h6 text-uppercase">
      {{ item.name }}
    </p>
    <div>
      <span>{{ item.address }} </span>
      <span>{{ item.city }} </span>
      <span>{{ item.postalCode }} </span>
      <span>{{ item.countryName }}</span>
    </div>
    <h1
      v-for="id of getPartnerIdentifiers(item)"
      :key="id"
      class="text-body-1"
    >
      <span class="text-body-2">{{ $t('supportedIdentifiers.' + id) }}: </span>{{ item[id] }}
    </h1>
    <div
      v-if="item.personInCharge"
      class="text--darken-4 uppercase"
    >
      <span class="text-body-2">{{ $t('documents.create.properties.personInCharge') }}: </span>{{ item.personInCharge }}
    </div>
    <div>{{ item.phone }}</div>
    <div>{{ item.email }}</div>
    <div>{{ item.website }}</div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PartnerCard',
  props: {
    item: { type: Object, default: () => { return {} } }
  },
  computed: {
    ...mapGetters([
      'getAllIdentifiers'
    ])
  },
  methods: {
    getPartnerIdentifiers (item) {
      return this.getAllIdentifiers.filter(id => item[id])
    }
  }
}
</script>
