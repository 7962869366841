import JSPath from 'jspath'
import { generateArrayFromObject } from '@/api/utils/generators'
import { mapState } from 'vuex'

export const documentContent = {
  props: {
    documentDetail: { type: Object, default: () => { return {} } },
    documentContent: { type: Object, default: () => { return { } } },
    documentCategory: { type: String, default: '' },
    documentIsAvailable: { type: Boolean, default: false },
    activeTab: { type: String, default: 'docHead' },
    displayRecipients: { type: Boolean, default: false }
  },
  computed: {
    ...mapState([
      'supportedPartnerTypes',
      'supportedBankTypes'
    ]),
    partnersList () {
      return generateArrayFromObject(
        this.documentContent,
        this.supportedPartnerTypes
      )
    },
    banksList () {
      return generateArrayFromObject(
        this.documentContent.document,
        this.supportedBankTypes
      )
    },
    displayBankAccounts () {
      return this.banksList.length > 0
    },
    displayAdditionalInfos () {
      const additionalInfos = this.documentContent.document.references
      return Array.isArray(additionalInfos) &&
      additionalInfos.length > 0
    }
  }
}

export const jsPath = {
  methods: {
    getValue (path) {
      return JSPath.apply(path, this.documentContent)
    }
  }
}
