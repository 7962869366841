<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('general.contacts') }}
    </p>
    <v-data-iterator
      :items="partnersList"
      hide-default-footer
      disable-pagination
      no-data-text=""
      class="row"
    >
      <template v-slot:item="{ item }">
        <partner-card :item="item" />
      </template>
    </v-data-iterator>
  </v-col>
</template>

<script>
import PartnerCard from '@/components/details/PartnerCard'

export default {
  name: 'PartnersGroup',
  components: {
    PartnerCard
  },
  props: {
    partnersList: { type: Array, default: () => { return [] } }
  }
}
</script>
