import { objectHasValues } from '@/api/utils/object_has_values'

export function generateArrayFromObject (object, keysArray) {
  return keysArray.reduce((acc, curr) => {
    const item = object[curr]
    if (objectHasValues(item)) {
      acc.push({
        category: curr,
        ...item
      })
    }
    return acc
  }, [])
}
