<template>
  <v-col
    cols="12"
    sm="6"
  >
    <p class="text-body-2 text-uppercase text-uppercase">
      {{ $t('documents.create.refs.' + item.scope) }}
    </p>
    <div class="text-h6">
      {{ item.identifier }}
    </div>
    <div v-if="item.date">
      {{ item.date }}
    </div>
    <div v-if="item.text">
      {{ item.text }}
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'AdditionalInfos',
  props: {
    item: { type: Object, default: () => { return {} } }
  }
}
</script>
