var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t('documents.create.refs.generic'))+" ")]),_c('v-data-iterator',{staticClass:"row",attrs:{"items":_vm.additionalInfos,"options":_vm.tableOptions,"footer-props":{
      itemsPerPageOptions: [15, 30, 50],
      itemsPerPageText: _vm.$t('general.rowsPerPage'),
      showCurrentPage: true,
      showFirstLastPage: true
    },"hide-default-footer":"","no-data-text":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('additional-infos',{attrs:{"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }